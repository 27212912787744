@import '~@angular/material/prebuilt-themes/indigo-pink.css';

:root {
  // Figma 色碼規格（紫色）
  --1-1-Purple-M: #430098;
  --1-2-Purple-M: #6933ad;
  --1-3-Purple-M: #8e66c1;
  --1-4-Purple-M: #b499d6;
  --1-5-Purple-M: #d9ccea;

  --2-1-Purple-D: #764392;
  --2-2-Purple-D: #9169a7;
  --2-3-Purple-D: #ad8ebd;
  --2-4-Purple-D: #c8b4d3;
  --2-5-Purple-D: #e4d9e9;

  // Figma 色碼規格（粉色）
  --3-1-pink: #c4197f;
  --3-2-pink: #d04799;
  --3-3-pink: #dc75b2;
  --3-4-pink: #e7a3cc;

  // Figma 色碼規格（藍色）
  --4-1-Blue: #008fb9;
  --4-2-Blue: #1f9cc1;
  --4-3-Blue: #47aecc;
  --4-4-Blue: #66bbd4;
  --4-5-Blue: #e8fafe;

  // Figma 色碼規格（灰色）
  --gray-1_1B1B1B: #1b1b1b;
  --gray-2_333333: #333;
  --gray-3_666666: #666;
  --gray-4_9E9E9E: #9e9e9e;
  --gray-5_C5C5C5: #c5c5c5;
  --gray-6-_DADCE0: #dadce0;

  // Figma 色碼規格（輔助用色）
  --black: #000;
  --white: #fff;
  --B: #0089ff;
  --R: #ff5849;
  --light-use: #cfbcb4;
  --gray1: #e8dfdb;

  // Figma 色碼規格（漸層用色）
  --linear-1: linear-gradient(180deg, #664392 0%, #430098 100%);
  --linear-2: linear-gradient(180deg, #baa1c8 0%, #a180cb 100%);
  --linear-3: linear-gradient(180deg, #ece4e1 0%, #e2d7d2 100%);
  --linear-4: linear-gradient(180deg, #df7ab6 0%, #d552a0 100%);
  --linear-5: linear-gradient(180deg, #4eb9dd 0%, #007a9e 100%);

  // Checkbox 勾選後顯示的顏色
  --mdc-checkbox-selected-focus-icon-color: var(--1-2-Purple-M);
  --mdc-checkbox-selected-hover-icon-color: var(--1-2-Purple-M);
  --mdc-checkbox-selected-icon-color: var(--1-2-Purple-M);
  --mdc-checkbox-selected-pressed-icon-color: var(--1-2-Purple-M);
  --mdc-checkbox-selected-focus-state-layer-color: var(--1-2-Purple-M);
  --mdc-checkbox-selected-hover-state-layer-color: var(--1-2-Purple-M);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--1-2-Purple-M);

  // Radio 勾選後顯示的顏色
  .mat-mdc-radio-button {
    --mdc-radio-selected-focus-icon-color: var(--1-2-Purple-M);
    --mdc-radio-selected-hover-icon-color: var(--1-2-Purple-M);
    --mdc-radio-selected-icon-color: var(--1-2-Purple-M);
    --mdc-radio-selected-pressed-icon-color: var(--1-2-Purple-M);
    --mat-radio-checked-ripple-color: var(--1-2-Purple-M);
  }

  // 下拉選單選中的選項顏色
  --mat-option-selected-state-label-text-color: var(--1-2-Purple-M);

  // Tab 顏色
  .mat-mdc-tab-group {
    --mdc-tab-indicator-active-indicator-color: var(--1-1-Purple-M);
    --mat-tab-header-active-label-text-color: var(--1-1-Purple-M);
    --mat-tab-header-active-ripple-color: var(--1-1-Purple-M);
    --mat-tab-header-inactive-ripple-color: var(--1-1-Purple-M);
    --mat-tab-header-active-focus-label-text-color: var(--1-1-Purple-M);
    --mat-tab-header-active-hover-label-text-color: var(--1-1-Purple-M);
    --mat-tab-header-active-focus-indicator-color: var(--1-1-Purple-M);
    --mat-tab-header-active-hover-indicator-color: var(--1-1-Purple-M);
  }
}

// check box 選擇前的底色
mat-checkbox.mat-mdc-checkbox
  div.mdc-checkbox
  input.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
  ~ div.mdc-checkbox__background {
  background-color: white;
  border: 1px solid var(--gray-3_666666);
}

*:not(mat-icon) {
  font-family: Roboto !important;
}

.mat-checkbox-frame {
  border-color: grey;
}

html,
body {
  // Spinner 顏色
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: var(--1-3-Purple-M);
  }
}

html {
  height: 100%;

  body {
    height: 100%;
    margin: 0;
    background-color: #cfcde4;
    touch-action: manipulation;

    *:not(mat-icon) {
      font-family: Roboto !important;
    }

    // 元件在載入內容時要顯示的動畫
    .loading {
      display: block;
      height: 100dvh;
      background-image: url(/assets/images/shared/loading.gif);
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: 50%;
    }

    button {
      color: #ffffff;
      border-radius: 24px;
      border-color: transparent;
      font-size: 14px;
    }

    button:disabled {
      color: var(--gray-4_9E9E9E) !important;
      background: var(--linear-3) !important;
    }

    hr {
      width: 100%;
      margin: 15px 0px;
      border: 1px solid var(--4-1-Blue);
    }

    mat-form-field {
      width: 100%;

      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        padding: 0;
        font-size: 14px;
      }

      .mat-datepicker-toggle {
        color: var(--1-1-Purple-M);
      }
    }

    mat-datepicker-content {
      .mat-calendar-body-selected {
        background-color: var(--1-5-Purple-M);
        color: var(--gray-1_1B1B1B);
      }

      .mat-calendar-body-today {
        border: none;
      }
    }

    mat-checkbox label {
      font-size: 16px;
    }

    mat-option.mat-mdc-option {
      // 多選下拉選單的 Checkbox
      .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
        background-color: var(--1-2-Purple-M);
      }

      // 選項右側的勾勾
      .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
      .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
        color: var(--1-2-Purple-M);
      }
    }

    .cdk-overlay-container {
      .cdk-global-overlay-wrapper {
        .verify-dialog-container {
          mat-dialog-container {
            .mdc-dialog__surface {
              background: #cfcde4;
            }
          }
        }
        .custom-container .mat-dialog-container {
          border-radius: 20px !important;
        }

        .confirm-dialog-container {
          mat-dialog-container {
            width: 100vw;
            border-radius: 50px;
            min-height: 100px;
            .mdc-dialog__surface {
              border-radius: 20px;
            }
          }
        }

        .download-dialog-container {
          mat-dialog-container {
            .mdc-dialog__surface {
              border-radius: 15px;
            }
          }
        }
      }
    }
  }
}
